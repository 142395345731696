import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { Fragment } from "react";
import { Outlet } from "react-router";
import Header from "../layout-parts/Header";

// --------------------------------------------
const BaseLayout = ({
  children
}) => {
  return <Fragment>
    <LayoutBodyWrapper >
      <Header />
      {children || <Outlet />}
    </LayoutBodyWrapper>
  </Fragment>;
};

export default BaseLayout;