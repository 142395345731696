import { lazy, Suspense } from "react";
import LoadingScreen from "components/LoadingScreen";
import BaseLayout from "layouts/layout/BaseLayout";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

const Homepage = Loadable(lazy(() => import("./pages/homepage")));

const Error = Loadable(lazy(() => import("./pages/404")));

const Whitepaper = Loadable(lazy(() => import("./pages/whitepaper")));

const routes = () => {
  return [
    {
      path: "/whitepaper",
      element: null,
      children: assetRoutes,
    },
    {
      path: "/",
      element: <BaseLayout />,
      children: dashboardRoutes,
    },
  ];
};
const dashboardRoutes = [
  {
    path: "homepage",
    element: <Homepage />,
  },
  {
    path: "whitepaper",
    element: <Whitepaper />,
  },
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "*",
    element: <Error />,
  },
];
const assetRoutes = [
  {
    path: "/whitepaper",
    element: <Whitepaper />,
  },

  {
    path: "*",
    element: <Error />,
  },
];
export default routes;
