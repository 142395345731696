import { Popover, Hidden, styled, alpha, SwipeableDrawer } from "@mui/material";
// ------------------------------------------------------------------


const LayoutPopover = props => {
    const {
        children,
    } = props;
    return <>
        <Hidden mdDown>
            <PopoverStyled
                {...props}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            />
        </Hidden>
        <Hidden mdUp>
            <SwipeableDrawerStyled
                {...props}
                anchor="bottom"
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            />
        </Hidden>
    </>;
};
const PopoverStyled = styled(Popover)(({ theme }) => ({
    '& .MuiPaper-root': {
        marginTop: '5px',
        border: `0.5px solid ${alpha(theme.palette.divider, 0.05)}`,
    }
}));

const SwipeableDrawerStyled = styled(SwipeableDrawer)(({ theme }) => ({
    '& .MuiPaper-root': {
        paddingTop: '30px',
        borderRadius: '15px 15px 0px 0px',
        '&:before': {
            content: '""',
            position: 'absolute',
            top: '10px',
            width: '50px',
            height: '5px',
            borderRadius: "5px",
            left: 'calc(50% - 25px)',
            background: alpha(theme.palette.divider, 0.1)
        }
    }
}));

export default LayoutPopover;