import { Box, styled } from "@mui/material";
import { Fragment } from "react";

const Wrapper = styled(Box)(({
  theme
}) => ({
  transition: "all 0.3s",
  [theme.breakpoints.down(1200)]: {
    width: "100%",
    marginLeft: 0,
  }
}));
const InnerWrapper = styled(Box)(({
  theme
}) => ({
  [theme.breakpoints.up("lg")]: {
    margin: "auto"
  }
})); // --------------------------------------------

// --------------------------------------------
const LayoutBodyWrapper = ({
  children,
  sx
}) => {
  return <Fragment>
    <Wrapper sx={sx}>
      <InnerWrapper>{children}</InnerWrapper>
    </Wrapper>
  </Fragment>;
};

export default LayoutBodyWrapper;