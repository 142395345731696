import axios from "axios";
const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);
export default axiosInstance;

export const devAxios = axios.create({
  baseURL: "https://api.dev.flaex.io",
  headers: {
    "Content-Type": "application/json",
  },
});
