import i18next from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
  en: {
    translation: {
      CRM: "CRM",
      Shop: "Shop",
      Chat: "Chat",
      Sales: "Sales",
      Results: "Results",
      Invoice: "Invoice",
      Payment: "Payment",
      Profile: "Profile",
      Account: "Account",
      Pricing: "Pricing",
      Checkout: "Checkout",
      Sessions: "Sessions",
      Ecommerce: "Ecommerce",
      Dashboards: "Dashboards",
      "Enrolled Courses": "Enrolled Courses",
      "Course Completed": "Course Completed",
      "Course in Progress": "Course in Progress",
      "Learning Management": "Learning Management",
      "Welcome Back! Watson": "Welcome Back! Watson",
      "Job Management": "Job Management",
      "User & Contact": "User & Contact",
      "User List": "User List",
      "User Grid": "User Grid",
      "Contact List": "Contact List",
      "Contact Grid": "Contact Grid",
      "Invoice List": "Invoice List",
      "Invoice Details": "Invoice Details",
      "Create Invoice": "Create Invoice",
      "Product Details": "Product Details",
      "Billing Address": "Billing Address",
      "Payment Complete": "Payment Complete",
      "Admin Ecommerce": "Admin Ecommerce",
      "Product List": "Product List",
      "Product Grid": "Product Grid",
      "Create Product": "Create Product",
      "Upcoming Task": "Upcoming Task",
      "Study Time Last Week": "Study Time Last Week",
      "Your Downloads": "Your Downloads",
      "Course Status": "Course Status",
      "We combine the deepest liquidity": "We combine the deepest liquidity",
      "Decentralized Margin Trading": "Decentralized Margin Trading",
      "DEXes with the richest Money Market of Lending Protocols": "DEXes with the richest Money Market of Lending Protocols",
      "Enter App": "Enter App",
      "White Paper": "White Paper",
      "Our Main Features": "Our Main Features",
      "DEXes with the richest Money Market of Lending Protocols": "DEXes with the richest Money Market of Lending Protocols",
      "Eliminates User's Fund Manipulation": "Eliminates User's Fund Manipulation",
      "Truly Decentralized Architecture that gives Traders full control over their funds at all time": "Truly Decentralized Architecture that gives Traders full control over their funds at all time",
      "Low cost": "Low cost",
      "Funding Rate as low as 0.8% APY": "Funding Rate as low as 0.8% APY",
      "Flexibility": "Flexibility",
      "Trading pairs are not limited": "Trading pairs are not limited",
      "Instant settlement": "Instant settlement",
      "Orders executed almost instantly": "Orders executed almost instantly",
      "Choose your Role": "Choose your Role",
      "Staking your Stablecoin and earn Real-Yield!": "Staking your Stablecoin and earn Real-Yield!",
      "Roadmap For 2024": "Roadmap For 2024",
      "We combine the deepest liquidity of traditional DEXes with the richest Money Market of Lending Protocols to create something truly remarkable!": "We combine the deepest liquidity of traditional DEXes with the richest Money Market of Lending Protocols to create something truly remarkable!",
      "DEX and Lending Protocol Integration": "DEX and Lending Protocol Integration",
      "Fully Integrate Uniswap V3 and AAVE V3": "Fully Integrate Uniswap V3 and AAVE V3",
      "Testnet Live": "Testnet Live",
      "Margin Trading Module and Investor Module Testnet Live on Goerli": "Margin Trading Module and Investor Module Testnet Live on Goerli",
      "Fully Audited": "Fully Audited",
      "Audit done for Smart Contract": "Audit done for Smart Contract",
      "V1 Live on Mainnet": "V1 Live on Mainnet",
      "Officially Go-live on Arbitrum Mainnet!": "Officially Go-live on Arbitrum Mainnet!",
      "Partners And Backers": "Partners And Backers",
      "BUILDER GRANTER": "BUILDER GRANTER",
      "LIQUIDITY PARTNER": "LIQUIDITY PARTNER",
      "Join Our Newsletter": "Join Our Newsletter",
      "Join our mailling list to keep up to date with our upcoming events": "Join our mailling list to keep up to date with our upcoming events",
      "With the perfect combination of DEXes and Lending Protocols, we provide a seamless Margin Trading Experience": "With the perfect combination of DEXes and Lending Protocols, we provide a seamless Margin Trading Experience",
      "DEXes with the richest Money Market of Lending Protocols": "DEXes with the richest Money Market of Lending Protocols"
    }
  },
  es: {
    translation: {

    }
  }
};
i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  }
});