import { AppBar, Box, ButtonBase, ClickAwayListener, Drawer, Hidden, IconButton, Stack, styled, Toolbar, Typography, useMediaQuery, useTheme } from "@mui/material";
import { SettingsContext } from "contexts/settingsContext";
import SearchIcon from "icons/SearchIcon";
import ThemeIcon from "icons/ThemeIcon";
import { Fragment, useContext, useState } from "react";
import LanguagePopover from "./popovers/LanguagePopover";
import NotificationsPopover from "./popovers/NotificationsPopover";
import ProfilePopover from "./popovers/ProfilePopover";
import SearchBar from "./SearchBar"; // ------------------------------------------------
import MenuLeft from "icons/MenuLeft";
import MenuLeftRight from "icons/MenuLeftRight";

// ------------------------------------------------
// custom styled components
const HeaderRoot = styled(AppBar)(({
  theme
}) => ({
  zIndex: 110000,
  boxShadow: "none",
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  minHeight: 60,
}));
const StyledToolBar = styled(Toolbar)(() => ({
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto"
  }
}));

export const Menus = [
  {
    title: 'About us',
    id: 'e-about-us'
  },
  {
    title: 'Features',
    id: 'e-features'
  },
  {
    title: 'Roadmap',
    id: 'e-roadmap'
  },
  {
    title: 'Partners',
    id: 'e-partners'
  },
  {
    title: 'Contract us',
    id: 'e-contract-us'
  }
]

const Header = props => {
  const [open, setOpen] = useState(false)
  const theme = useTheme()
  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    setOpen(false)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return <Fragment>
    <HeaderRoot position="absolute">
      <StyledToolBar >
        <Stack flexGrow={1} px={{ xs: 2, md: 10 }} py={2} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
          <ButtonBase>
            <img src="/logo.png" style={{ height: 40 }} />
            <Typography variant="h4" ml={1}>Flæx</Typography>
          </ButtonBase>
          <Hidden mdDown>
            <Stack direction={'row'} alignItems={'center'}>
              {
                Menus.map((item, index) => <ButtonBase onClick={() => handleClickScroll(item.id)} key={index} sx={{ padding: '0.4rem 1rem', borderRadius: '5px', '&:hover': { background: 'rgba(255,255,255,0.1)' } }}>
                  <Typography >{item.title}</Typography>
                </ButtonBase>)
              }
            </Stack>
          </Hidden>
          <Hidden mdUp>
            <ButtonBase onClick={() => setOpen(true)} sx={{ padding: '0.4rem 1rem', borderRadius: '5px', '&:hover': { background: 'rgba(255,255,255,0.1)' } }}>
              <MenuLeft />
            </ButtonBase>
          </Hidden>
        </Stack>
      </StyledToolBar>
    </HeaderRoot>
    <Drawer open={open} onClose={() => setOpen(false)} anchor="right" sx={{ zIndex: 1000000 }} PaperProps={{
      sx: {
        minWidth: 300,
        alignItems: 'flex-start',
        background: theme.palette.background.default
      }
    }}>
      <Stack p={1.7}>
        <ButtonBase>
          <img src="/logo.png" style={{ height: 40 }} />
          <Typography variant="h4" ml={1}>Flæx</Typography>
        </ButtonBase>
      </Stack>
      <Stack width={'100%'}>
        {
          Menus.map((item, index) => <ButtonBase onClick={() => handleClickScroll(item.id)} key={index} sx={{ padding: '0.4rem 1rem', justifyContent: 'flex-start', height: 45, '&:hover': { background: 'rgba(255,255,255,0.1)' } }}>
            <Typography >{item.title}</Typography>
          </ButtonBase>)
        }
      </Stack>
    </Drawer>
  </Fragment>;
};

export default Header;